<template>
  <div
    class="w-full rounded-sm bg-[#d9d9d9]"
    :class="isFixed ? 'h-[8px]' : 'h-[4px]'"
    @click="moveBar($event)"
  >
    <div
      class="h-full rounded-sm bg-correct"
      :style="'width: ' + getPassedPlayDist + '%'"
    ></div>
  </div>
</template>

<script setup>
const props = defineProps({
  audio: {
    type: Object,
    default: null,
  },
  isFixed: {
    type: Boolean,
    default: false,
  },
});
const audioStore = useAudioStore();

const isAudioStarted = computed(() => {
  return props.audio?.src === audioStore.getSrc;
});

const getPassedPlayDist = computed(() => {
  return isAudioStarted.value ? audioStore.getPassed : 0;
});

const moveBar = (e) => {
  if (!props.audio) return;

  const rect = e.target.getBoundingClientRect();
  const x = e.clientX - rect.left; // координаты клика по оси Х
  const w = e.target.offsetWidth; // длина элемента
  const p = x / (w / 100); // процент от шкалы

  audioStore.move(p);
};
</script>

<style lang="scss"></style>
