<template>
  <div
    class="flex select-none items-center"
    :class="[
      isFixed ? fixedClasses : defaultClasses,
      { mask: audioError && !isFixed },
    ]"
  >
    <div
      v-if="!isFixed"
      class="text-smaller flex w-fit justify-center font-bold"
    >
      {{ getTimeLeft }}
    </div>
    <div class="mx-6 w-full">
      <!-- :class="getProgressBarWidth" -->
      <AudioPlayProgress
        v-bind="isAudioCurrentlyPlaying && { audio: getAudio }"
        :is-fixed="isFixed"
      />
    </div>
    <div
      class="flex items-center"
      :class="isFixed ? 'mt-6 w-full flex-wrap relative' : 'w-fit'"
    >
      <div
        v-if="isFixed"
        class="close-audio-bar-mob highlight order-2 mr-[35px] cursor-pointer text-[#4D4D4D] transition duration-300 ease-in-out hover:text-correct md:order-1"
        @click="closeFixed"
      >
        <SvgIcon
          name="close"
          class="icon icons-extra-big"
          style="height: 44px; width: 44px"
        />
      </div>
      <div
        class="manage-buttons-block order-1 flex w-fit items-center md:order-2"
      >
        <div v-if="isFixed">
          <div
            class="highlight text-[#4D4D4D] transition duration-300 ease-in-out hover:text-correct"
            :class="
              !currentAudioList[indexCurrentAudio - 1] &&
              'hover:text-[#4D4D4D] opacity-50 pointer-events-none'
            "
          >
            <SvgIcon
              name="play_prev"
              height="14"
              class="icon icons-medium cursor-pointer"
              @click="prevAudio"
            />
          </div>
        </div>
        <div :class="isFixed ? 'mx-[22px]' : ''">
          <div v-if="!isAudioCurrentlyPlaying" @click="play">
            <SvgIcon
              name="play-circle"
              class="icons-extra-big highlight cursor-pointer"
              style="height: 44px; width: 44px"
            />
          </div>
          <div v-else @click="pause">
            <SvgIcon
              name="pause-circle"
              class="icons-extra-big highlight cursor-pointer"
              style="height: 44px; width: 44px"
            />
          </div>
        </div>
        <div v-if="isFixed">
          <div
            class="highlight text-[#4D4D4D] transition duration-300 ease-in-out hover:text-correct"
            :class="
              !currentAudioList[indexCurrentAudio + 1] &&
              'hover:text-[#4D4D4D] opacity-50 pointer-events-none'
            "
          >
            <SvgIcon
              name="play_next"
              height="14"
              class="icon icons-medium cursor-pointer"
              @click="nextAudio"
            />
          </div>
        </div>
      </div>
      <div
        v-if="isFixed"
        class="width-mob-wrap order-3 flex font-roboto md:ml-8"
      >
        <div class="h-20 w-20 min-w-[80px] overflow-hidden rounded-xl">
          <img
            v-lazy-load="
              data?.image && data?.image?.preview
                ? data.image.preview
                : '/images/default-image.png'
            "
            class="h-full object-cover"
            alt=""
          />
        </div>
        <div
          class="width-mob-content ml-3 flex flex-col justify-center md:ml-6"
        >
          <div class="flex">
            <div
              v-for="author in data?.authors"
              :key="author.id"
              class="z-30 mb-2 flex items-center"
            >
              <div class="mr-[8px] h-[12px] w-[12px] bg-audiolecture"></div>
              <p
                class="text-[14px] font-bold uppercase leading-[17px] text-[#E1E1E1]"
              >
                {{ author.firstname }} {{ author.surname }}
              </p>
            </div>
          </div>

          <div class="scroll-desc text-[18px] leading-[23px] text-white-100">
            <span>
              {{ data?.title }}
            </span>
          </div>
        </div>
      </div>
      <div
        v-if="route.path.startsWith('/teacher')"
        class="absolute bottom-2 right-1.5 grid h-8 w-8 cursor-pointer place-content-center lg:bottom-[initial] lg:right-4"
      >
        <a :href="getAudio?.src" download>
          <SvgIcon name="download" class="icon icons-big" />
        </a>
      </div>

      <div v-if="playlist.length > 0" :class="isFixed ? '' : 'w-2/12'">
        <div
          class="flex cursor-pointer items-center justify-center"
          @click="isPlaylistShown = !isPlaylistShown"
        >
          <span class="text-smaller-caps mr-2 font-bold">Открыть плейлист</span>
          <SvgIcon name="dots-nine" style="width: 1.5rem; height: 1.5rem" />
        </div>
        <!--      <badge-->
        <!--        text="Открыть плейлист"-->
        <!--        icon="dots-nine"-->
        <!--        type="control"-->
        <!--        :class="{ active: isPlaylistShown }"-->
        <!--        @click.native="isPlaylistShown = !isPlaylistShown"-->
        <!--      />-->
      </div>

      <AudioPlayList
        v-if="isPlaylistShown"
        ref="playlistRef"
        v-model:currently-playing-id="currentlyPlayingId"
        :playlist="playlist"
      />
    </div>
  </div>
</template>

<script setup>
import { digits } from '@/services/helpers';
const props = defineProps({
  filePath: {
    type: String,
    default: '',
  },
  playlist: {
    type: Array,
    default: () => [],
  },
  isFixed: {
    type: Boolean,
    default: false,
  },
  course: {
    type: Object,
    default: null,
  },
});
const emit = defineEmits(['end-play', 'start-play']);
// const route = useRoute();
const audioStore = useAudioStore();
const toast = useToast();
const route = useRoute();

/** refs */
const audio = ref(null);
const audioError = ref(false);
const duration = ref(0);
const isPlaylistShown = ref(false);
const currentlyPlayingId = ref(null);
const defaultClasses = ref(
  'relative justify-between flex-row-reverse rounded-[12px] bg-white-100 shadow-drop-0 py-[18px] px-6',
);
const fixedClasses = ref(
  'fixed flex-col bottom-0 right-0 left-0 z-50 w-full justify-center bg-[#1f1f1ff5] bg-blend-multiply pt-7 pb-6 px-6 rounded-t-xl',
);
const playlistRef = ref(null);

/** click outside */
onClickOutside(playlistRef, (event) => {
  console.log(event), closePlaylist();
});

/** computed */
const isAudioStarted = computed(() => {
  if (!props.filePath) return getAudio.value?.src === audioStore.getSrc;
  return (
    getAudio.value?.src === audioStore.getSrc &&
    data.value?.path === props.filePath
  );
});

const data = computed(() => {
  return audioStore.getCurrentAudioData;
});

const getAudio = computed(() => {
  return audioStore.getAudio;
});

const getDuration = computed(() => {
  return duration.value || audioStore.getDuration;
});

const isAudioCurrentlyPlaying = computed(() => {
  return isAudioStarted.value ? audioStore.isPlayed : false;
});

const getCurrentPlayTime = computed(() => {
  return isAudioStarted.value ? audioStore.getCurrentTime : 0;
});

const getTimeLeft = computed(() => {
  if (audioError.value || !getDuration.value) return '-00:00';

  const initialTime = getDuration.value - getCurrentPlayTime.value;
  const hours = Math.floor(initialTime / 3600);
  const minutes = digits(Math.floor((initialTime % 3600) / 60));
  const seconds = digits(initialTime % 60);

  return hours > 0
    ? `-${hours}:${minutes}:${seconds}`
    : `-${minutes}:${seconds}`;
});

const currentAudioList = computed(() => {
  return audioStore.getCurrentAudioList;
});

const indexCurrentAudio = computed(() => {
  return currentAudioList.value.findIndex(
    (audio) => audio.id === data.value.id,
  );
});

/** watch */
watch(currentlyPlayingId, () => {
  playNew();
});
watch(getCurrentPlayTime, (val) => {
  if (getDuration.value && val >= getDuration.value) {
    // audioStore.stop();
    // emit('end-play');
    nextAudio();
  }
});
watch(
  () => props.course,
  () => {
    if (!props.course?.path) return;
    loadData(props.course.path, true, props.course);
  },
  { deep: true },
);

/** hooks */
onMounted(() => {
  if (!props.isFixed) {
    let source = '';
    if (props.playlist.length > 0) {
      source = props.playlist[0].filePath;
      currentlyPlayingId.value = props.playlist[0].id;
    } else {
      source = props.filePath;
    }
    if (!source) {
      toast.error(
        'При загрузке аудиофайла произошла ошибка, попробуйте позже!',
      );
      return;
    }
    loadData(source);
  }
});

/** methods */
const loadData = (source, isStart = false, data = null) => {
  return new Promise((resolve, reject) => {
    audio.value = new Audio(`https://histrf.ru${source}`);
    audio.value.preload = 'metadata';
    audioError.value = false;

    audio.value.addEventListener('error', () => {
      audioError.value = true;
      toast.error(
        'При загрузке аудиофайла произошла ошибка, попробуйте позже!',
      );
      audioStore.stop();
      reject(new Error('error file loading'));
    });

    audio.value.addEventListener('loadedmetadata', () => {
      if (
        audio.value.readyState > 1 ||
        (!isNaN(audio.value.duration) && audio.value.duration > 1)
      ) {
        if (!duration.value) duration.value = parseInt(audio.value.duration);

        if (isStart) {
          audioStore.start({
            audio: audio.value,
            data,
          });
          audioStore.setCurrentAudioData(data);
          audioStore.setCurrent(data);
        }
        resolve();
      }
    });
  });
};

const play = () => {
  if (audioError.value) return;

  if (isAudioStarted.value) {
    audioStore.play();
  } else {
    if (!props.isFixed) {
      audioStore.stop();
      audioStore.hideFixedAudioBar();
    }

    audioStore.start({
      audio: audio.value,
      data: props.course,
    });
    audioStore.setCurrentAudioData(props.course);
    audioStore.setCurrent(props.course);
    emit('start-play');
  }
};

const playNew = async () => {
  await audioStore.pause();

  const source = props.playlist.find(
    (el) => el.id === currentlyPlayingId.value,
  );

  await loadData(source.filePath);
};

// const getAudioSource = async (current = null) => {
//   if (!current) {
//     if (route.query?.page > 1) {
//       current = (+route.query.page - 1) * 8 + (props.data.idx + 1);
//     } else {
//       current = props.data.idx + 1;
//     }
//   }

//   const { data } = await api.lectures.getAudioLectures({
//     per_page: 1,
//     page: current,
//   });

//   audioStore.setCurrentAudioData(data.data[0]);
//   audioStore.setCurrent(current);
// };

const pause = () => {
  audioStore.pause();
};

const removeItemFromList = (index) => {
  audioStore.rmItemFromList(index);
};

const prevAudio = async () => {
  await audioStore.stop();
  const prevAudioIndex = indexCurrentAudio.value - 1;
  const prevAudio = currentAudioList.value[prevAudioIndex];
  if (!prevAudio) return;
  loadData(prevAudio.path, true, prevAudio).catch(() => {
    removeItemFromList(prevAudioIndex);
    prevAudio();
  });
};

const nextAudio = async () => {
  await audioStore.stop();
  const nextAudioIndex = indexCurrentAudio.value + 1;
  const nextAudio = currentAudioList.value[nextAudioIndex];
  if (!nextAudio) return;
  loadData(nextAudio.path, true, nextAudio).catch(() => {
    removeItemFromList(nextAudioIndex);
    nextAudio();
  });
};

const closeFixed = () => {
  audioStore.stop();
  audioStore.hideFixedAudioBar();
  audioStore.setCurrentAudioList([]);
  audioStore.setCurrentCategoryList(null);
};

const closePlaylist = () => {
  isPlaylistShown.value = false;
};
</script>

<style lang="scss">
.mask {
  @apply relative before:inset-0 before:absolute before:bg-white-50/70;
}
.highlight {
  -webkit-tap-highlight-color: #0000;
}
@media (max-width: 780px) {
  .manage-buttons-block {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .close-audio-bar-mob {
    position: absolute;
    right: 0;
    top: 0;
    margin: 0;
  }
  .width-mob-wrap {
    max-width: 100%;
  }
  .width-mob-content {
    max-width: calc(100% - 145px);
  }

  @keyframes scroll {
    0% {
      transform: translate(0, 0);
    }
    20% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(-85%, 0);
    }
    80% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  .scroll-desc {
    display: block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
  }
  .scroll-desc span {
    display: inline-block;
    animation: scroll 30s infinite linear;
  }
}
</style>
